import React from "react";
import './App.css';

// CONSTANTS
const TWITTER_URL = "https://twitter.com/meebugs";
const DISCORD_URL = "https://discord.gg/xr9C25Aa3F";
const MAGICEDEN_URL = "https://magiceden.io/collections/ethereum/0x3b400259B6E8D003C9973a79Fb9828fBA9Fb87a0";
const ETHERSCAN_URL = "https://etherscan.io/address/0x3b400259B6E8D003C9973a79Fb9828fBA9Fb87a0";
const HEYMINT_URL = "https://launchpad.heymint.xyz/mint/meebugs-by-larva-lads";


function App() {


  return (
    <div className="flex items-center justify-center h-screen">

      <div className="w-129 max-[640px]:w-128">
          <img src="meebugs.gif" className="mr-auto  h-auto w-129 mb-9" alt="animated gif displaying a selection of meebugs"></img>
          <p className="mb-6 text-base max-[700px]:text-baseSm"><b>Meebugs</b> are unique 3D voxel characters, created by a custom generative algorithm, then registered on the Ethereum blockchain. The collection plays with the <a className="underline" href="https://meebits.app/" target="_blank">Meebits</a> iconography and is a continuation of the <a className="underline" href="https://larvalads.app/" target="_blank">Larva Lads</a> universe.</p>
          <p className="mb-6 text-base max-[700px]:text-baseSm">More than just a "jpeg", each Meebug includes a 3D model file ready to be animated, 3D-printed, or used in 3D games and metaverse experiences.</p>
          <p className="mb-6 text-base max-[700px]:text-baseSm">Larva Lads holders are eligible to claim Meebugs for free. And an additional supply is available for anyone to mint.</p>
          <a href={HEYMINT_URL} target="_blank"><img src="button-5.png" className="mr-auto -ml-1.5 h-auto w-small mb-7" alt="button linking to the minting site"></img></a>
          <p className="mb-6 text-base max-[700px]:text-baseSm">
            <a className="underline" href={MAGICEDEN_URL} target="_blank" rel="noreferrer">Magic Eden
            </a> // <a className="underline" href={DISCORD_URL} target="_blank" rel="noreferrer">Discord
            </a> // <a className="underline" href={TWITTER_URL} target="_blank" rel="noreferrer">Twitter
            </a> // <a className="underline" href={ETHERSCAN_URL} target="_blank" rel="noreferrer">Etherscan</a>
          </p>
      </div>

    </div>
  );
};

export default App;


